import {
  BookingItem,
  BookingRequest,
  GuestDetail,
  roomsToEnumeratedString,
  TentTypeV1,
} from '@camp67/model';

import {
  getDateString,
  offeringEndsAt,
  offeringStartsFrom,
  validateInRange,
  validateInRangeFromString,
} from '@camp67/model/booking/dates';
import { initialBookingRequest, initialPrice } from './reservation-state';
import { validate as validateEmail } from 'email-validator';
import { phone as validatePhone } from 'phone';
import { addHours } from 'date-fns/addHours';
import {
  queryTentAvailability,
  queryTentAvailabilityDates,
} from '../network/query-tent-availability';
import { captureException } from '@sentry/remix';
import { capacityV1 } from '@camp67/model/booking/configuration/v1';
import phoneCodes from '../constants/phoneCodes.json';
import { PriceComputation, calculatePrice } from '@camp67/model/booking/price';
import { nanoid } from 'nanoid';
import { toSentenceCase } from '../components/booking-form/util';
import { isAfter } from 'date-fns';

type ListenerMap = Map<string, VoidFunction>;
type ErrorKey =
  | 'start-date'
  | 'end-date'
  | 'email'
  | 'phone'
  | 'adult-count'
  | 'child-count';

class ReservationStore {
  private data: BookingRequest = { ...initialBookingRequest };
  private tentTypes: TentTypeV1[] = Object.values(TentTypeV1);
  private availableDates: string[] = [];
  private phone: { dialCode: string; rawNumber: string } = {
    dialCode: '',
    rawNumber: '',
  };
  private price: PriceComputation = initialPrice;
  private listeners: ListenerMap = new Map();
  private verificationId = '';

  private errors: Map<string, string> = new Map();

  onChange(id: string, listener: VoidFunction) {
    this.listeners.set(id, listener);
  }
  removeOnChange(id: string) {
    this.listeners.delete(id);
  }
  clearListeners() {
    this.listeners.clear();
  }

  getStartOfBookingTimeRange() {
    const timezoneOffsetInHours = Math.abs(new Date().getTimezoneOffset() / 60);
    return addHours(offeringStartsFrom, timezoneOffsetInHours);
  }
  getEndOfBookingTimeRange() {
    const timezoneOffsetInHours = Math.abs(new Date().getTimezoneOffset() / 60);
    return addHours(offeringEndsAt, timezoneOffsetInHours);
  }
  getAvailableDates() {
    return this.availableDates;
  }
  getTentTypes() {
    return this.tentTypes;
  }

  chooseDateStart(from: Date | undefined) {
    if (from && !validateInRange(from)) {
      console.warn('invalid start date', from);
      return this.errored('start-date', 'invalid start date');
    }

    this.mutate(() => {
      if (!from) {
        this.data.reservation.startDate = '';
      } else {
        this.data.reservation.startDate = getDateString(from);
      }

      if (!isAfter(this.data.reservation.endDate, this.data.reservation.startDate)) {
        this.data.reservation.endDate = '';
      }

      this.recomputePrice();
      this.updateAvailableTentTypes();
    });
  }

  chooseDateRange(to: Date | undefined) {
    const availableDates = this.availableDates;

    if (!availableDates || availableDates.length === 0) {
      console.warn('No available dates for the selected rooms');
      return;
    }

    if (to && !availableDates.includes(getDateString(to))) {
      console.warn('Invalid end date', to);
      return this.errored('end-date', 'Invalid end date');
    }

    this.mutate(() => {
      this.data.reservation.endDate = to ? getDateString(to) : '';
      if (!to) {
        this.data.reservation.endDate = '';
      } else {
        this.data.reservation.endDate = getDateString(to);
      }

      this.recomputePrice();
      this.updateAvailableTentTypes();
    });
  }
  validateDateRange() {
    return (
      validateInRangeFromString(this.data.reservation.startDate) &&
      validateInRangeFromString(this.data.reservation.endDate)
    );
  }

  choosePrimaryContact = (id: string) => {
    const guest = this.data.reservation.guests.details.find((g) => g.id === id);
    if (!guest) {
      console.warn('no guest found with id', id);
      return;
    }

    this.mutate(() => {
      this.data.primaryContact.id = id;
      this.data.primaryContact.name = guest.name;
    });
  };
  setPrimaryEmail = (email: string) => {
    this.mutate(() => {
      this.data.primaryContact.email = email;
    });
  };
  setPrimaryPhoneNumber = (phone: string) => {
    this.mutate(() => {
      this.phone.rawNumber = phone;
      this.data.primaryContact.phone = this.phone.dialCode + this.phone.rawNumber;
    });
  };

  validatePrimaryContact() {
    const { name, email, phone } = this.data.primaryContact;
    if (!name) {
      return false;
    }

    let isValid = true;

    if (!validateEmail(email)) {
      this.errored('email', 'Please provide a valid email address.');
      isValid = false;
    }

    if (!validatePhone(phone).isValid) {
      this.errored('phone', 'Please provide a valid phone number, with country code.');
      isValid = false;
    }

    if (!isValid) {
      this.mutatedErrors();
    }

    return isValid;
  }
  canValidatePrimaryContact() {
    const { name, email, phone } = this.data.primaryContact;
    return name.length > 0 && email.length > 0 && phone.length > 0;
  }

  addRoom(type: TentTypeV1) {
    this.mutate(() => {
      const allocationIndex = this.data.reservation.rooms.findIndex((r) => r[0] === type);
      const allocation =
        allocationIndex !== -1
          ? this.data.reservation.rooms[allocationIndex]
          : ([type, 0] as BookingItem);

      allocation[1] = Math.min(allocation[1] + 1, 20);

      if (allocationIndex === -1) {
        this.data.reservation.rooms.push(allocation);
      } else {
        this.data.reservation.rooms[allocationIndex] = allocation;
      }

      this.recomputePrice();
    });

    // Trigger the date update based on the new room selection
    this.updateAvailableDates();
  }
  removeRoom(type: TentTypeV1) {
    this.mutate(() => {
      const allocationIndex = this.data.reservation.rooms.findIndex((r) => r[0] === type);
      if (allocationIndex === -1) {
        console.warn('no room found of type', type);
        return;
      }

      const allocation = this.data.reservation.rooms[allocationIndex];
      if (allocation[1] === 0 || allocation[1] === 1) {
        this.data.reservation.rooms.splice(allocationIndex, 1);
      } else {
        allocation[1] = Math.max(allocation[1] - 1, 0);
      }

      this.recomputePrice();
    });

    // Trigger the date update based on the new room selection
    this.updateAvailableDates();
  }
  hasRoom(type: TentTypeV1) {
    return this.data.reservation.rooms.some((r) => r[0] === type && r[1] > 0);
  }
  hasTentType(type: TentTypeV1) {
    return this.tentTypes.includes(type);
  }
  getRoomCount = (type: TentTypeV1) => {
    const allocation = this.data.reservation.rooms.find((r) => r[0] === type);
    return allocation ? allocation[1] : 0;
  };
  getTotalRoomCount() {
    return this.data.reservation.rooms.reduce((acc, [, count]) => acc + count, 0);
  }
  getCapacityOfRooms() {
    return this.data.reservation.rooms.reduce(
      (acc, [type, count]) => acc + count * (capacityV1.get(type) ?? 0),
      0,
    );
  }
  clearRooms() {
    this.mutate(() => {
      this.data.reservation.rooms.length = 0;
    });
  }

  validateRooms() {
    return (
      this.data.reservation.rooms.length > 0 &&
      this.data.reservation.rooms.every((r) => r[1] > 0)
    );
  }

  setAdultCount = (count: number) => {
    if (count < 1) {
      console.warn('invalid adult count', count);
      return this.errored('adult-count', 'invalid adult count');
    }

    this.mutate(() => {
      this.data.reservation.guests.adult = count;
      this.synchroniseGuestCount();
    });
  };
  setChildCount = (count: number) => {
    if (count < 0) {
      console.warn('invalid child count', count);
      return this.errored('child-count', 'invalid child count');
    }

    this.mutate(() => {
      this.data.reservation.guests.children = count;
      this.synchroniseGuestCount();
    });
  };
  synchroniseGuestCount() {
    const guestCount = this.getGuestCount();
    const currentGuestCount = this.data.reservation.guests.details.length;

    if (guestCount < currentGuestCount) {
      this.data.reservation.guests.details.length = guestCount;
    } else {
      for (let i = currentGuestCount; i < guestCount; i++) {
        this.data.reservation.guests.details.push({
          id: nanoid(),
          name: '',
          age: 0,
          gender: 'none',
        });
      }
    }
  }
  initialiseGuestDetails() {
    this.mutate(() => {
      this.synchroniseGuestCount();
    });
  }
  clearGuests() {
    this.mutate(() => {
      this.data.reservation.guests.details.length = 0;
    });
  }
  validateGuests() {
    return (
      this.data.reservation.guests.details.length > 0 &&
      this.data.reservation.guests.details.every(
        (g) => g && g.id && g.name && g.gender !== 'none' && g.age !== 0,
      )
    );
  }
  canValidateGuests() {
    return this.data.reservation.guests.details.length > 0;
  }
  getGuestCount() {
    return this.data.reservation.guests.adult + this.data.reservation.guests.children;
  }
  validateGuestCount() {
    return this.data.reservation.guests.adult > 0;
  }
  updateGuestDetail = (id: string, detail: Partial<GuestDetail>) => {
    const guest = this.data.reservation.guests.details.find((g) => g.id === id);
    if (!guest) {
      console.warn('no guest found with id', id);
      return;
    }

    this.mutate(() => {
      Object.assign(guest, detail);
    });
  };

  getAcceptedConditions() {
    return this.data.primaryContact.acceptedConditions;
  }
  setAcceptedConditions(accepted: boolean) {
    this.mutate(() => {
      this.data.primaryContact.acceptedConditions = accepted;
    });
  }

  initializeCountryCode(code: string) {
    if (!this.phone.dialCode) {
      this.mutate(() => {
        this.phone.dialCode = phoneCodes.find((c) => c.c === code)?.d ?? '+91';
      });
    }
  }
  setCountryCode = (code: string) => {
    this.mutate(() => {
      this.phone.dialCode = code;
      this.data.primaryContact.phone = code + this.phone.rawNumber;
    });
  };
  getCountryCode = () => {
    return this.phone.dialCode;
  };
  getPhoneNumber = () => {
    return this.phone.rawNumber;
  };

  reset() {
    this.mutate(() => {
      this.data = initialBookingRequest;
      this.price = initialPrice;
    });
  }

  private mutate(mutator: () => undefined) {
    this.errors.clear();

    mutator();

    for (const listener of this.listeners.values()) {
      listener();
    }
  }
  private mutatedErrors() {
    for (const listener of this.listeners.values()) {
      listener();
    }
  }

  private errored(key: ErrorKey, message: string) {
    this.errors.set(key, message);
  }

  private async updateAvailableTentTypes() {
    const start = this.data.reservation.startDate;
    const end = this.data.reservation.endDate;

    try {
      if (!start || !end) {
        return;
      }

      const availableTents = await queryTentAvailability(start, end);

      this.mutate(() => {
        this.tentTypes = availableTents;

        // if the selected tent type is not available, remove it
        for (const [type] of this.data.reservation.rooms) {
          const tentTypeStillAvailable = availableTents.includes(type);

          if (!tentTypeStillAvailable) {
            const tentTypesStillAvailable = this.data.reservation.rooms.filter(([t]) =>
              availableTents.includes(t),
            );
            this.data.reservation.rooms = tentTypesStillAvailable;
          }
        }
      });
    } catch (e) {
      captureException(e);
      console.error(e);
    }
  }

  private async updateAvailableDates() {
    const selectedRooms = this.data.reservation.rooms;

    if (!selectedRooms || selectedRooms.length === 0) {
      console.warn('No rooms selected for date availability check');
      return;
    }

    try {
      const availableDates = await queryTentAvailabilityDates({ rooms: selectedRooms });
      this.mutate(() => {
        this.availableDates = availableDates;
      });
    } catch (e) {
      console.error('Error fetching available dates:', e);
      this.mutate(() => {
        this.availableDates = [];
      });
    }
  }

  private recomputePrice() {
    if (!this.validateDateRange()) {
      return;
    }

    if (!this.validateRooms()) {
      return;
    }

    this.price = calculatePrice(
      this.data.reservation.startDate,
      this.data.reservation.endDate,
      this.data.reservation.rooms,
    );
  }

  get state() {
    return this.data;
  }

  get tents() {
    return this.tentTypes;
  }

  get cost() {
    return this.price;
  }

  get canSubmit() {
    return (
      this.canValidatePrimaryContact() &&
      this.canValidateGuests() &&
      this.validateDateRange() &&
      this.validateRooms()
    );
  }

  get isReadyForValidation() {
    return (
      this.validatePrimaryContact() &&
      this.validateDateRange() &&
      this.validateRooms() &&
      this.validateGuests()
    );
  }

  validate = () => {
    // check all unsanitized inputs
    if (!this.validatePrimaryContact()) {
      return false;
    }

    return this.validateDateRange() && this.validateRooms() && this.validateGuests();
  };

  startVerification = () => {
    this.verificationId = nanoid();
  };
  getVerification = () => {
    return this.verificationId;
  };
  resetVerification = () => {
    this.verificationId = nanoid();
  };

  getError(key: ErrorKey) {
    return this.errors.get(key) ?? '';
  }

  hasErrors() {
    return this.errors.size > 0;
  }

  getRoomsToEnumeratedString() {
    return roomsToEnumeratedString(this.state.reservation.rooms, ', ');
  }
  getStep1Summary(): Step1Summary {
    return {
      rooms: roomsToEnumeratedString(this.state.reservation.rooms, ', '),
      step: 1,
    };
  }
  isStep1Complete() {
    return this.validateRooms();
  }
  resetStep1 = () => {
    this.mutate(() => {
      this.resetStep3();
      this.resetStep2();
      this.state.reservation.rooms = [];
    });
  };

  getStep2Summary(): Step2Summary {
    const adults = this.state.reservation.guests.adult;

    return {
      startDate: this.state.reservation.startDate,
      endDate: this.state.reservation.endDate,
      guests:
        adults === 0
          ? ''
          : `${adults} Adult${adults !== 1 ? 's' : ''} ${this.state.reservation.guests.children} Children`,
      step: 2,
    };
  }
  isStep2Complete() {
    return (
      this.isStep1Complete() && this.validateDateRange() && this.validateGuestCount()
    );
  }
  resetStep2 = () => {
    this.mutate(() => {
      this.resetStep3();
      this.state.reservation.startDate = '';
      this.state.reservation.endDate = '';
      this.state.reservation.guests.adult = 1;
      this.state.reservation.guests.children = 0;
    });
  };

  private getGuestName(name: string) {
    return name.includes(' ') ? name.split(' ')[0] : name;
  }
  getStep3Summary(): Step3Summary {
    const validGuests = this.validateGuests();
    return {
      guestDetails: this.state.reservation.guests.details.map((detail) =>
        validGuests
          ? `${this.getGuestName(detail.name)}, ${detail.age}, ${toSentenceCase(detail.gender)}`
          : '',
      ),
      primary: [
        this.state.primaryContact.name,
        this.state.primaryContact.email,
        this.state.primaryContact.phone,
      ],
      step: 3,
    };
  }
  isStep3Complete() {
    return (
      this.isStep2Complete() && this.validateGuests() && this.canValidatePrimaryContact()
    );
  }
  resetStep3 = () => {
    this.mutate(() => {
      this.state.reservation.guests.details = [];
      this.state.primaryContact = {
        id: 'initial-id',
        name: '',
        email: '',
        phone: '',
        acceptedConditions: false,
      };
      this.synchroniseGuestCount();
    });
  };
}

export const reservationStore = new ReservationStore();

export type StepSummary = Step1Summary | Step2Summary | Step3Summary;

export type Step1Summary = {
  rooms: string;
  step: 1;
};

export type Step2Summary = {
  startDate: string;
  endDate: string;
  guests: string;
  step: 2;
};

export type Step3Summary = {
  guestDetails: string[];
  primary: string[];
  step: 3;
};
